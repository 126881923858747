import { getAuthHeader } from "@src/app/superagent";
import { environmentConfig } from "@src/appV2/environment";
import { AttendanceConfirmationChannel } from "@src/appV2/Shifts/UrgentShifts/constant";
import { logAndThrowApiError, logApiFailureEvent } from "@src/lib/analytics";
import { Shift } from "@src/lib/interface";
import request from "superagent";

export const fetchPotentialShiftsForQualifiedFacilities = async (
  agentId: string,
  facilityIds: string[],
  dateFilter: { start: string; end: string }
): Promise<{
  success: boolean;
  error?: string;
  totalPotentialShiftsCount?: number;
}> => {
  return await request
    .post(
      `${environmentConfig.REACT_APP_BASE_API_URL}/calendar/potentialShiftsForQualifiedFacilities`
    )
    .set(await getAuthHeader())
    .send({ agentId, facilityIds, dateFilter })
    .then(({ body }) => body);
};

export interface CancelShiftPayload {
  shiftId: string;
  reasonType: string;
  reasonDescription: string;
  clientCancellationTime: string;
  coordinates?: [Longitude: number, Latitude: number];
}

export const selfCancelShift = async (payload: CancelShiftPayload): Promise<Shift> => {
  return await request
    .post(`${environmentConfig.REACT_APP_BASE_API_URL}/v1/shifts/selfCancel`)
    .set(await getAuthHeader())
    .send({
      ...payload,
      isNative: true,
    })
    .then(({ body }) => body.result)
    .catch((error) => {
      logApiFailureEvent(error);
      throw error;
    });
};

export const fetchShiftInfo = async (shiftId: string): Promise<Shift> => {
  return await request
    .get(`${environmentConfig.REACT_APP_BASE_API_URL}/shift/agentSignature/${shiftId}`)
    .retry(1, (err) => {
      if (!err) {
        return false;
      }
      return true;
    })
    .set(await getAuthHeader())
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

export const markShiftAsNonInstantPay = async (shiftId: string, reason: string): Promise<Shift> => {
  return await request
    .post(`${environmentConfig.REACT_APP_BASE_API_URL}/v1/shifts/markNonInstant`)
    .set(await getAuthHeader())
    .send({
      shiftId,
      reason,
    })
    .then(({ body }) => body.response)
    .catch((error) => {
      logAndThrowApiError(error);
    });
};

export async function changeNFCTag(shiftId: string) {
  return await request
    .put(`${environmentConfig.REACT_APP_BASE_API_URL}/shift/changeNFCTag`)
    .set(await getAuthHeader())
    .send({
      shiftId,
    })
    .then(({ body }) => body.response)
    .catch(logApiFailureEvent);
}

/**
 * @todo Add correct typing for ShiftDetails based on what the API
 * provides. It currenlty only contains the Shift + NFC hasehs (which will be removed)
 */
export const fetchShiftDetails = async ({ shiftId }: { shiftId: string }): Promise<Shift> => {
  return await request
    .get(`${environmentConfig.REACT_APP_BASE_API_URL}/v1/shifts/myShifts/details/${shiftId}`)
    .retry(1, (err) => {
      if (!err) {
        return false;
      }
      return true;
    })
    .set(await getAuthHeader())
    .then(({ body }) => body.response);
};

export const fetchNextTwoDaysShifts = async (tmz: string): Promise<any> => {
  return await request
    .get(`${environmentConfig.REACT_APP_BASE_API_URL}/v1/shifts/myShifts/nextTwoDays`)
    .retry(1, (err) => {
      if (!err) {
        return false;
      }
      return true;
    })
    .set(await getAuthHeader())
    .query({ tmz })
    .then(({ body }) => body.response ?? []);
};

export const checkAttendance = async (urgentShiftsServiceUrl: string, payload: any) => {
  return request
    .get(`${urgentShiftsServiceUrl}/attendance/check`)
    .query({ ...payload })
    .set(await getAuthHeader())
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

export const checkAttendanceV1 = async (
  urgentShiftsServiceUrl: string,
  payload: any
): Promise<Record<"attendanceCheck", boolean>> => {
  return request
    .get(`${urgentShiftsServiceUrl}/v1/attendance/check`)
    .query({ ...payload })
    .set(await getAuthHeader())
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

export const checkAttendanceBulk = async (urgentShiftsServiceUrl: string, payload: any) => {
  return request
    .post(`${urgentShiftsServiceUrl}/attendance/check/bulk`)
    .set(await getAuthHeader())
    .send({ ...payload })
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

export interface ConfirmAttendancePayload {
  shiftId: string;
  longitude?: number;
  latitude?: number;
  eta?: string;
  channel?: AttendanceConfirmationChannel;
  sendbirdMessageId?: number;
  workplaceId?: string;
}

export async function confirmAttendance(
  urgentShiftsServiceUrl: string,
  payload: ConfirmAttendancePayload
): Promise<Record<"attendanceConfirm", boolean>> {
  return request
    .post(`${urgentShiftsServiceUrl}/attendance/confirm`)
    .set(await getAuthHeader())
    .send({ ...payload })
    .then(({ body }) => body)
    .catch((error) => {
      return Promise.reject(error?.response?.body ?? error);
    });
}

export interface SentHomeRequestPayload {
  description: string;
  reason: string;
  shiftId: string;
  atFacility?: boolean;
}

export const requestSentHomeConfirmation = async (payload: SentHomeRequestPayload) => {
  return await request
    .patch(
      `${environmentConfig.REACT_APP_BASE_API_URL}/shifts/${payload.shiftId}/facility-cancelled-me/request`
    )
    .set(await getAuthHeader())
    .send(payload)
    .catch((error) => {
      logApiFailureEvent(error);
      throw error;
    });
};

export const fetchSentHomeRequests = async () => {
  return request
    .get(`${environmentConfig.REACT_APP_BASE_API_URL}/fcm/pending`)
    .set(await getAuthHeader())
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};
